<template>
    <section class="container project-two-container mb-5">
        <div class="">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 px-4 align-tems-center">
            <div class="col">
                <h1 style="font-size:40px; font-weight:600" class="text-start text-dark">{{projectBackgroundTitle}}</h1>
          <p class="mt-3 mb-3 text-start text-dark" style="font-size:21.25px;line-height:26.95px;margin:9.625px 0px 0px;padding: 0px 56.6562px 0px 0px;font-weight:400;color: #5D5D5D;">{{projectOs}}</p>
            </div>
            <div class="col">
              <p class="project-sm-p text-start text-dark mb-4" style="font-size:20px;line-height:31.85px;vertical-align:baseline;letter-spacing:-0.35px;word-spacing:0px;margin:0px;padding:0px; color: #5D5D5D; font-weight:500;">
                {{ projectBackgroundDescription }}
              </p>
              <p class="project-sm-p text-start text-dark mb-4" style="font-size:20px;line-height:31.85px;vertical-align:baseline;letter-spacing:-0.35px;word-spacing:0px;margin:0px;padding:0px; color: #5D5D5D; font-weight:500;">
                {{ projectBackgroundDescriptionTwo }}
              </p>

            </div>
          </div>
        </div>
      </section>
</template>
<script>
export default {
    props: {
    projectBackgroundTitle: String,
    projectBackgroundDescription : String,
    projectBackgroundDescriptionTwo : String,
  }
  
  }
</script>
<style scoped>



  @media (max-width: 728px) {
    .project-two-heading {
        font-size: 3rem !important;
        margin: 0px !important;
      }
      .project-two-container {
        margin-top: 0px !important;
        padding-top: 50px !important;
        padding-left: 20px !important;
        padding-right: 10px !important;
      }
      .project-sm-p {
        font-size: 1.2rem !important;
        line-height: 1.4em !important;
      }
  }
</style>