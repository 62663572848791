<template>
    <section class="about container">
      <div class="mt-5">
        <div class="mt-3 mt-3 row row-cols-1 row-cols-sm-2 row-cols-md-2 px-4">
          <h1 class="ml10">
            <span class="text-wrapper">
              <span class="letters mb-4">{{ title }}</span>
            </span>
          </h1>
          <div class="">
            <p class="art-works-p">
               {{ paragraph }}
            </p>
            <!-- <p class="art-works-p">
              I have honed my skills in Figma, the industry-standard UX design
              tool, and have a deep understanding of the Adobe Creative Suite,
              enabling the creation of designs that are not only aesthetically
              pleasing, but also practical and efficient.
            </p>
            <p class="art-works-p">
              In addition to my design expertise, my background in front-end
              development gives me a valuable understanding of the development
              process and enables me to effectively communicate with developers. I
              love working remotely and collaborating with project teams.
            </p> -->
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  export default{
    props:{
        title: String,
        paragraph:String
    }
  }
</script>
  <style>
  .about {
    margin-top: 150px;
  }
  .about-pic {
    margin-top: 200px;
  }
  .art-works-p {
    font-family: Inter, sans-serif;
    font-size: 22px;
    line-height: 42px;
    vertical-align: baseline;
    letter-spacing: -0.28px;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 30px;
    text-align: start;
  }
  .letters {
    font-family: Inter, sans-serif;
    font-size: 32px;
    line-height: 41.6px;
    vertical-align: baseline;
    letter-spacing: -0.64px;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
    text-align: start !important;
  }
  .ml10 {
    text-align: start;
  }
  </style>
  