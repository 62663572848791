import TulpenViewVue from '@/views/TulpenView.vue'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MoonTime from '../views/MoonTime.vue'
import Pridelands from '@/views/PridelandsView.vue'
import CvLetter from '@/views/CvLetter.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tulpen',
    name: 'tulpenView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TulpenViewVue
  },
  {
    path: '/moontime',
    name: 'moontimeView',
    component: MoonTime
  },
  {
    path: '/pridelands',
    name: 'pridelandsView',
    component: Pridelands
  },
  {
    path: '/cvletter',
    name: 'cvletter',
    component: CvLetter
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes
})

export default router
