<template>
    <section id="imageID" class="mb-4 project-image">
        <img class="img-fluid mb-4" :src="require('@/assets/images/' +  imageUrl + '')" alt="">
    </section>
</template>
<script>
export default{
    props: {
    imageUrl: String,
    }
}
</script>

<style>
.project-two-text {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .project-image{
    margin-top: 140px;
  }
  @media (max-width: 728px) {
    .project-image{
        margin-top: 100px !important;
    }
    
  
  }
</style>