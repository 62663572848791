<template>
    <div class="container">
        <h1>{{ centeredText }}</h1>
    </div>
</template>
<script>
export default{
    props:{
        centeredText: String
    }
}
</script>
<style scoped>
.container{
    margin-top: 100px !important;
    margin-bottom: 50px !important;
}
h1{
    font-size: 40px;
    font-weight: 600;
    color:#000;
}

</style>