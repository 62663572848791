<template>
    <div class="container">
        <footer class="d-flex flex-wrap justify-content-between align-items-center py-4 border-bottom px-4">
          
          <ul class="nav  justify-content-start">
            <p style="font-size: 22px;font-weight:500; font-family:Inter;"> &copy; {{ currentYear }}  Designed by Enokia</p>
          </ul>
      
          <ul class="nav d-flex justify-content-end align-items-center">
            <li class="nav-item"><a href="https://dribbble.com/enokia" class="footer-nav nav-link px-2"><img src="@/assets/images/dribbble-round-svgrepo-com.svg" alt="" style="height:40px"></a></li>
            <li class="nav-item"><a href="https://www.linkedin.com/in/enokia" class="footer-nav nav-link px-2"><img src="@/assets/images/linkedin-round-svgrepo-com.svg" alt="" style="height:40px"></a></li>
            <li class="nav-item"><a href="https://www.behance.net/enokia" class="footer-nav nav-link px-2"><img src="@/assets/images/behance-round-svgrepo-com.svg" alt="" style="height:40px"></a></li>
            <li class="nav-item"><a href="https://wa.me/0557582670" class="footer-nav nav-link px-2"><img src="@/assets/images/what.svg" alt="" style="height:40px"></a></li>
          </ul>
        </footer>
      </div>
</template>
<script>
export default{
  data() {
  return {
    currentYear: null
  }
},
created() {
  this.currentYear = new Date().getFullYear();
}

}
</script>