<template>
  <button
    @click="scrollToTop"
    v-show="showScrollBtn"
    type="button"
    class="btn btn-floating btn-lg"
    id="btn-back-to-top"
    ref="btn-back-to-top"
  >
    <lord-icon
      src="https://cdn.lordicon.com/xsdtfyne.json"
      trigger="hover"
      style="width: 30px; height: 30px"
    >
    </lord-icon>
  </button>
  <project-hero
    projectTitle="Pridelands"
    projectDescription="Pridelands is a talent network and marketplace to share
      narratives, buy & sell and invest in people and projects
      making impact across africa and the diaspora."
    projectOs="UX Design Case Study"
  />
  <pridelands-case-study-download projectPdf="View Pridelands Case Study " />
  <pridelands-web-link projectPdf="View Pridelands Website " />
  <project-details
    client="Pride Labs Inc, USA"
    type="Product Design"
    year="2022 - 2023"
  />
  <project-image imageUrl="pridelands1.png" />
  <project-info
    projectHeader="Pridelands App"
    projectInfo="As the UX designer and researcher, I conducted research to empathise with 
      users and turned gathered insights from research into an app design with users 
      front and center. I tested and iterated on the design to ensure users needs are 
      met."
  />
  <contained-image imageUrl="Mask-group-4.png" />
  <project-challenges
    projectBackgroundTitle="Challenges"
    projectBackgroundDescription="One major challenge of the Pridelands app project was the need to create a 
      centralized platform that adequately catered to the needs of the African and 
      Diaspora communities. The problem statement identified that these 
      communities lacked the visibility, recognition, and resources needed to bring 
      their innovative ideas to life and drive positive change in their communities. "
    projectBackgroundDescriptionTwo="Additionally, there was a need to connect these communities with a supportive 
    network and potential investors to help them realize their full potential. 
    Extensive user research and journey mapping was required to develop a 
    comprehensive talent network and marketplace that empowered these 
    communities to showcase their talents, products, and projects."
  />
  <project-role
    projectRoleTitle="My Role"
    projectRoleDescription="As the Lead UX designer, I conducted research to empathize with users and 
    turned gathered insights from research into an app design with users front and 
    center. I tested and iterated on the design to ensure users needs are met."
    projectRoleOne=" Analyzing and synthesizing research findings to inform design decisions"
    projectRoleTwo="Build a Style Guide and Design System"
    projectRoleThree="Collaborating with cross-functional teams to create wireframes, prototypes, 
    and high-fidelity mockups"
    projectRoleFour="Testing and evaluating the app usability"
    projectRoleFive="Ensuring that the app is accessible and inclusive for all users."
  />
  <contained-image imageUrl="Pridelands-Wireframes.png" />
  <contained-image imageUrl="Pridelands-Low-fidelity prototype.png" />
  <contained-image imageUrl="pl.jpg" />
  <project-image imageUrl="Pridelands-Design System.png" />
  <project-info
    projectHeader="Pride Design System: A creative and cohesive African-Inspired UI System"
    projectInfo="Pridelands aims to empower African and Diaspora professionals and youth by providing 
    a talent network and marketplace for showcasing their talents, products, and projects. 
    To capture the African theme and inspire creativity, we developed the Pride Design 
    System, a scalable and versatile design system inspired by African art, culture, and 
    landscapes. It follows an atom, molecule, and organism ideology, with atoms     
    serving as the foundational elements such as typography and color, molecules     
    being combinations of atoms like buttons and cards, and organisms being     
    combinations of molecules like the header and footer. This design system     
    provides a consistent and organised approach to design elements,     
    contributing to the app's overall success."
  />
  <contained-image imageUrl="Prideland-Marketplace.png" />
  <project-info
    projectHeader="Design System Impact"
    projectInfo="The Pride Design System has a significant impact on the Pridelands app, as it 
    provides a consistent and scalable visual language, which is essential for a 
    successful app design. The system aligns with the app's mission to empower 
    African and Diaspora professionals, by providing a visual language that 
    captures the essence of African culture and inspires creativity. By using the 
    Pride Design System, the Pridelands app will stand out, capture the attention of 
    users, and drive engagement and satisfaction."
  />
  <project-hr />
  <wedey-next-project
    nextProjectTitle="Wedey"
    nextProjectPar="WeDey provides customers with easy access to a wide range
    of products and services from major local markets and farms
    across Africa. With shopping reps available to fulfill orders"
    nextProjectParOne=""
    nextProjectParTwo=""
    nextProjectText="Next Project"
    nextProjectImage="wedeyNext.png"
  />
  <project-hr />
  <project-footer />
</template>

<script>
import ProjectHero from '@/components/ProjectHero.vue'
import ProjectImage from '@/components/ProjectImage.vue'
import ProjectDetails from '@/components/ProjectDetails.vue'
import ProjectInfo from '@/components/ProjectInfo.vue'
import ContainedImage from '@/components/ContainedImage.vue'
import ProjectChallenges from '@/components/ProjectChallenges.vue'
import ProjectRole from '@/components/PojectRole.vue'
import ProjectHr from '@/components/ProjectHr.vue'
import WedeyNextProject from '@/components/WedeyNextProject.vue'
import ProjectFooter from '@/components/ProjectFooter.vue'
import PridelandsCaseStudyDownload from '@/components/PridelandsCaseStudyDownload.vue'
import PridelandsWebLink from '@/components/PridelandsWebLink.vue'
export default {
  name: 'PridelandsView',
  components: {
    ProjectHero,
    ProjectImage,
    ProjectDetails,
    ProjectInfo,
    ContainedImage,
    ProjectChallenges,
    ProjectRole,
    ProjectHr,
    WedeyNextProject,
    ProjectFooter,
    PridelandsCaseStudyDownload,
    PridelandsWebLink
  },
  data() {
    return {
      showScrollBtn: false
    }
  },
  methods: {
    // This method scrolls the page to the top when the button is clicked
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    // This method is called when the user scrolls the page
    handleScroll() {
      // If the user has scrolled down more than 100 pixels, show the button
      if (window.pageYOffset > 100) {
        this.showScrollBtn = true
      } else {
        this.showScrollBtn = false
      }
    }
  },
  mounted() {
    // Add an event listener to detect when the user scrolls the page
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style scoped>
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: -5px;
  z-index: 999;
  /* position: relative; */
}
</style>
