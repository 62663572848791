<template>
  <section class="container mt-5 mb-3">
    <h2 class="featured-work mb-4 px-4 text-start">
      Featured Work  <span style="color: #767676"> · 2009-2023</span>
    </h2>
    <div class="px-4">
      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-2"
        data-masonry='{"percentPosition": true }'
      >
        <div class="col py-1">
          <router-link to="/tulpen" class="text-decoration-none">
            <div class="card mb-4 border-0 rounded-5">
              <img
                class="card-img-top"
                src="@/assets/images/tulpen.png"
                alt="Card image"
                style="width: 100%"
              />
              <div class="card-body" style="background: #f7f7f7">
                <h4 class="card-title mb-1">Tulpen</h4>
                <p class="card-text">IOS - Android Apps</p>
              </div>
            </div>
          </router-link>
          <a href="" class="text-decoration-none">
            <div class="card mb-4 border-0">
              <img
                class="card-img-top"
                src="@/assets/images/Mask-group.png"
                alt="Card image"
                style="width: 100%"
              />
              <div class="card-body">
                <h4 class="card-title">Loyalstar</h4>
                <p class="card-text">Redesign . Responsive Website</p>
              </div>
            </div>
          </a>
          <router-link to="/pridelands" class="text-decoration-none">
            <div class="card mb-4 border-0">
              <img
                class="card-img-top"
                src="@/assets/images/Mask-group-4.png"
                alt="Card image"
                style="width: 100%"
              />
              <div class="card-body">
                <h4 class="card-title">Pridelands</h4>
                <p class="card-text">Mobile App . Website</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col py-1">
          <router-link to="/moontime" class="text-decoration-none">
            <div class="card mb-4 border-0">
              <img
                class="card-img-top"
                src="@/assets/images/Mask-group-1.png"
                alt="Card image"
                style="width: 100%"
              />
              <div class="card-body">
                <h4 class="card-title">Moontime</h4>
                <p class="card-text">Redesign . Responsive Website</p>
              </div>
            </div>
          </router-link>
          <router-link to="https://loyalstar.co/" class="text-decoration-none">
            <div class="card mb-4 border-0">
              <img
                class="card-img-top"
                src="@/assets/images/Mask-group-3.png"
                alt="Card image"
                style="width: 100%"
              />
              <div class="card-body">
                <h4 class="card-title">Wedey</h4>
                <p class="card-text">IOS - Android App</p>
              </div>
            </div>
          </router-link>
          <!-- <div class="card border-0">
                <img class="card-img-top" src="@/assets/images/62b427ac5b1d8f581a094663_project-1-thumbnail.jpg" alt="Card image" style="width:100%">
                <div class="card-body">
                  <h4 class="card-title">Vertical</h4>
                  <p class="card-text"> Product design</p>
                </div>
              </div> -->
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.featured-work {
  font-size: 18px;
  line-height: 23.4px;
  vertical-align: baseline;
  letter-spacing: -0.54px;
  word-spacing: 0px;
  margin: 0px;
  padding: 0px;
  font-weight: 600;
}
.card {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}
.card:hover {
  top: -3px;
  cursor: pointer;
  opacity: 0.8;
}
.card-img-top {
  border-radius: 30px 30px 0px 0px;
}
.card-body {
  border-radius: 0px 0px 30px 30px;
  background: #fafafa;
  padding: 24px 28px 24px 24px;
}
.card-title {
  font-size: 20px;
  line-height: 26px;
  vertical-align: baseline;
  letter-spacing: -0.6px;
  word-spacing: 0px;
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-weight: 600;
  text-align: start;
  color: black;
}

.card-text {
  font-family: Inter;
  font-size: 20px;
  line-height: 26px;
  vertical-align: baseline;
  letter-spacing: -0.6px;
  word-spacing: 0px;
  margin: 0px;
  padding: 0px;
  font-weight: 600;
  font-style: normal;
  color: #757575;
  text-decoration: none solid rgb(117, 117, 117);
  text-align: start;
  text-indent: 0px;
}
</style>
