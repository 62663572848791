<template>
    <div class="container mb-0 ">
     <flickity  class="flickity" ref="flickity" :options="flickityOptions">
      <img class="carousel-cell carousel-logo" src="@/assets/images/Loyalstar-Logo.svg">
      <img class="carousel-cell carousel-logo-two" src="@/assets/images/Novartis-Logo.svg">
      <img class="carousel-cell carousel-logo" src="@/assets/images/Glo-Logo.svg">
      <img class="carousel-cell carousel-logo" src="@/assets/images/Pridelands-Logo.svg">
      <img class="carousel-cell carousel-logo-two" src="@/assets/images/Kalahari-Resorts-Logo.svg">
      <img class="carousel-cell carousel-logo" src="@/assets/images/Loyalty-Insurance-Logo.svg">
        </flickity>
    </div>
</template>
<script>
import Flickity from 'vue-flickity'

export default {
  components: {
    Flickity
  },
  data () {
    return {
       flickityOptions: {
       cellAlign: 'center',
       freeScroll: true,
       contain: true,
       wrapAround: true,
       autoPlay: true,
       autoPlay: 1500,
       pauseAutoPlayOnHover: false,
       prevNextButtons: false,
       pageDots: false
        // any options from Flickity can be used
      }
    }
  }
}

</script>
<style scoped>
.container{
  margin-top: 100px !important;
}
.carousel {
  display: block;
  width: 100%;
}

.carousel-logo {
  width: 100%;
  max-width: 100px;
  height: 120px;
}
.carousel-logo-two {
  max-width: 260px;
  height: 160px;
}

.carousel-cell {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
}
@media (max-width: 728px) {
  .centered{
    bottom: 25%;
    left: 7%;
    width:100% !important;
    font-size: 36px !important;
    padding: 0 8px !important;
  }
}
</style> 

