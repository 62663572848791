<template>
    <section class="project-two-text container">
        <div class=" mt-5 px-4">
          <hr class="p2-hr" style="color: #000000; ">
        </div>
      </section>
</template>

<style scoped>
.p2-hr {
    color: #000000a7 !important;
    height: 30px !important;
  }
</style>