<template>
    <section class="container about-pic">
        <div class="mt-3  mt-3 row row-cols-1 row-cols-sm-2 row-cols-md-2 g-3 px-4">
          <h1 class="ml10">
            <span class="text-wrapper">
              <span class="letters text-dark">Experience</span>
              <h3 class="company-date mb-3 mt-3">Refer to my CV</h3>
            </span>
          </h1>
          <div class="">
            <div class="mb-4 mt-3">
            <h2 class="company-name">Pride Lab Inc. USA</h2>
              <h3 class="company-name mb-3">Remote - UI Designer and Frontend Developer</h3>
              <h4 class="company-date">2021 - 2022</h4>
         </div>
         <hr class="mt-4">
         <div class="mb-3 mt-5">
          <h2 class="company-name">Loyalty Insurance Co. Ltd</h2>
            <h3 class="company-name mb-3">Contract - UX Designer</h3>
            <h4 class="company-date">2019 - 2022</h4>
       </div>
       <hr class="mt-4">
       <div class="mb-3 mt-5">
        <h2 class="company-name">Strec Global - UPSA</h2>
          <h3 class="company-name mb-3">Remote - UI/UX Designer</h3>
          <h4 class="company-date">2016 - 2019</h4>
     </div>
    </div>
    </div>
    </section>
</template>
<style>
.about-pic {
    margin-top: 200px;
  }
.art-works-p {
    font-family: Inter, sans-serif;
    font-size: 28px;
    line-height: 42px;
    vertical-align: baseline;
    letter-spacing: -0.28px;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 30px;
  }
  .letters {
    font-family: Inter, sans-serif;
    font-size: 32px;
    line-height: 41.6px;
    vertical-align: baseline;
    letter-spacing: -0.64px;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
  }
  .company-name {
    font-family: Inter, sans-serif;
    font-size: 20px;
    line-height: 26px;
    vertical-align: baseline;
    letter-spacing: -0.6px;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
    color: black;
    text-align: start;
  }
  .company-date {
    font-family: Inter, sans-serif;
    font-size: 20px;
    line-height: 30px;
    vertical-align: baseline;
    letter-spacing: normal;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 400;
    color: #757575;
    text-align: start;
  }
  hr {
    color: #75757558 !important;
    height: 30px !important;
  }
</style>
