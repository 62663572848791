<template>
    <section class="container about-pic cta-container" id="contact">
        <div class="p-md-5 mb-4   rounded-5 text-center cta">
          <div class="container-fluid py-5 text-center">
            <h1 class="mb-4 cta-text" style="" >Want to create <br> something awesome? <br> Drop me an email.</h1>
            <a href="mailto:hi@enochpro.com" class="btn btn-dark btn-lg px-4 me-md-2 rounded-pill" >hi@enochpro.com<i class="uil uil-arrow-up-right"></i></a>
          </div>
        </div>
    </section>
</template>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.cta{
    background-image: linear-gradient(to right bottom, #ffede9, #ffede9, #ffede9, #ffede9, #ffede9);
}
.cta-text{
    font-family: 'Inter';
    font-size: 64px;
    line-height: 73px;
    letter-spacing: -2.56px;
    font-weight: 600;
    color:#000;
}
@media (max-width: 728px) {
    .cta-text{
        font-size: 34px;
        line-height: 41px;
    }
    .cta-container{
        margin-top: 100px !important;
    }
}
</style>
