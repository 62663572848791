<template>
    <section class="container about-pic">
        <div class="mt-3  mt-3 row row-cols-1 row-cols-sm-2 row-cols-md-2 g-3 px-4">
         <div class="services">
          <h1 class="ml10">
            <span class="text-wrapper">
              <span class="letters text-dark">Services</span>
            </span>
          </h1>
        </div>
          <div class="">
            <div class="mb-4 mt-lg-4">
              <h2 class="company-name mb-4">Specialised UX & Product Design</h2>
         </div>
         <hr class="mt-4">
         <div class="mb-3 mt-5">
          <h2 class="company-name mb-4">Design Systems & Strategy</h2>
       </div>
       <hr class="mt-4">
       <div class="mb-3 mt-5">
        <h2 class="company-name mb-4">Web & Mobile App Design</h2>
     </div>
     <hr class="mt-4">
     <div class="mb-3 mt-5">
      <h2 class="company-name mb-4">Responsive Web Design</h2>
    </div>
    <hr class="mt-4 ">
    <div class="mb-3 mt-5">
      <h2 class="company-name mb-4">Ai & Contemporary Art</h2>
    </div>
    </div>
    </div>
    </section>
</template>
<style>
.about-pic {
    margin-top: 200px;
  }

  .art-works-p {
    font-family: Inter, sans-serif;
    font-size: 28px;
    line-height: 42px;
    vertical-align: baseline;
    letter-spacing: -0.28px;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 30px;
  }

  .letters {
    font-family: Inter, sans-serif;
    font-size: 32px;
    line-height: 41.6px;
    vertical-align: baseline;
    letter-spacing: -0.64px;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
  }

  .company-name {
    font-family: Inter, sans-serif;
    font-size: 20px;
    line-height: 26px;
    vertical-align: baseline;
    letter-spacing: -0.6px;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
  }
  .company-date {
    font-family: Inter, sans-serif;
    font-size: 20px;
    line-height: 30px;
    vertical-align: baseline;
    letter-spacing: normal;
    word-spacing: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 400;
    color: #757575;
  }
  hr {
    color: #75757558 !important;
    height: 30px !important;
  }
  @media (max-width: 728px) {
  .services {
    margin-bottom: 50px !important;
  }
}
</style>
