<template>
  <section id="topDiv" class="container project-two-container mb-3">
    <div class="">
      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-2 px-4 align-tems-center"
      >
        <div class="col">
          <h1
            class="project-two-heading text-dark text-start"
            style="
              font-size: Inter;
              font-size: 4rem;
              line-height: 56px;
              vertical-align: baseline;
              letter-spacing: -1.456px;
              word-spacing: 0px;
              padding: 0px;
              font-weight: 500;
              font-style: normal;
              font-variant: normal;
              text-transform: none;
              text-decoration: none solid rgb(0, 0, 0);
              text-align: start;
              text-indent: 0px;
              font-family: Inter;
              font-weight: bold;
            "
          >
            {{ projectTitle }}
          </h1>
          <p
            class="mt-3  text-start text-dark"
            style="
              font-size: 21.25px;
              line-height: 26.95px;
              margin: 9.625px 0px 0px;
              padding: 0px 56.6562px 0px 0px;
              font-weight: 400;
              color: #5d5d5d;
            "
          >
            {{ projectOs }}
          </p>
        </div>
        <div class="col">
          <p
            class="project-sm-p text-start text-dark"
            style="
              font-size: 22;
              line-height: 31.85px;
              vertical-align: baseline;
              letter-spacing: -0.35px;
              word-spacing: 0px;
              margin: 0px;
              padding: 0px;
              color: #5d5d5d;
              font-weight: 500;
            "
          >
            {{ projectDescription }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TulpenCaseStudyDownload from '@/components/TulpenCaseStudyDownload.vue'
export default {
  props: {
    projectTitle: String,
    projectDescription: String,
    projectOs: String,
    projectPdf: String
  },
  components:{
    TulpenCaseStudyDownload
  }
}
</script>
<style scoped>
.project-two-container {
  margin-top: 10px !important;
  padding-top: 70px;
  font-size: 20.75px;
  font-weight: 500;
}

@media (max-width: 728px) {
  .project-two-heading {
    font-size: 3rem !important;
    margin: 0px !important;
  }
  .project-two-container {
    margin-top: 0px !important;
    padding-top: 50px !important;
    padding-left: 20px !important;
    padding-right: 10px !important;
  }
  .project-sm-p {
    font-size: 1.2rem !important;
    line-height: 1.4em !important;
  }
}
</style>
