<template>
  <button
  @click="scrollToTop" v-show="showScrollBtn"
  type="button"
  class="btn btn-floating btn-lg"
  id="btn-back-to-top"
  ref="btn-back-to-top"
>
  <lord-icon
    src="https://cdn.lordicon.com/xsdtfyne.json"
    trigger="hover"
    style="width: 30px; height: 30px"
  >
  </lord-icon>
</button>
  <app-hero/>
  <div id="features" class="mb-5"></div>
  <app-featured-work/>
  <div class="" id="about"></div>
  <app-about/>
  <client-carousel-vue/>
  <app-experience-client title="Employers & Clients" paragraph=" I bring a wealth of experience and a dedication to delivering exceptional UX
  designs. My extensive portfolio of work with top employers and clients,
  includes Loyalstar (Ghana), Youma (Netherlands), Pride Labs, Inc (a Silicon
  Valley startup), Novartis (Switzerland), Kalahari Resorts (USA), Globacom
  (Nigeria), WeDey (Ghana), and more. My commitment to
  excellence, passion for creating delightful and user-centered experiences, and
  desire to learn and constantly expand my skills and knowledge, makes me a
  valuable asset to any team."/>
  <app-experience-client title="Working Remote" paragraph="As a UX designer, working remotely has been a game changer for me. Not only
  has it allowed me to eliminate the time and energy I used to spend on
  commuting, but it's also allowed me to work in a more efficient and productive
  manner. With remote work, I have the flexibility to take on projects from
  anywhere in the world, which opens up a world of opportunities and allows me
  to tap into a diverse range of experiences and perspectives. Furthermore, the
  lack of a rigid 9-to-5 schedule has enabled me to work during my most
  productive hours, whether that be early in the morning or late at night. All in all,
  remote work has allowed me to optimize my work-life balance and has been a
  great experience for me as a designer."/>
  <app-experience-client title="Passionate Artistic Family" paragraph="When not pushing design boundaries, I delve into other creative endeavors. I
  come from a family of artists with passion for contemporary art and AI-driven
  projects. I trained under my father, a handwoven silk thread art pioneer in West
  Africa since my childhood. This has shaped my artistic and innovative talents. I
  am driven to explore this interests, let it inspire work with employers and also
  my wife’s educational project, an AI artist and preschool educator."/>
  <project-image-vue imageUrl="Enoch-Pro-Portfolio-Banner.png"/>
  <app-experience/>
  <app-services/>
  <app-cta/>
  <project-footer/>
</template>

<script>
// @ is an alias to /src
import AppHero from '@/components/AppHero.vue'
import AppFeaturedWork from '@/components/AppFeaturedWork.vue'
import AppAbout from '@/components/AppAbout.vue'
import ProjectImage from '@/components/ProjectImage.vue'
import AppExperience from '@/components/AppExperience.vue'
import AppServices from '@/components/AppServices.vue'
import AppCta from '@/components/AppCta.vue'
import ProjectFooter from '@/components/ProjectFooter.vue'
import AppExperienceClient from '@/components/AppExperienceClient.vue'
import ClientCarouselVue from '@/components/ClientCarousel.vue'
import ProjectImageVue from '@/components/ProjectImage.vue'

export default {
  name: 'HomeView',
  components: {
    AppHero,
    AppFeaturedWork,
    AppAbout,
    ProjectImageVue,
    AppExperience,
    AppServices,
    AppCta,
    ProjectFooter,
    AppExperienceClient,
    ClientCarouselVue
  },
  data() {
    return {
      showScrollBtn: false,
    };
  },
  methods: {
    // This method scrolls the page to the top when the button is clicked
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // This method is called when the user scrolls the page
    handleScroll() {
      // If the user has scrolled down more than 100 pixels, show the button
      if (window.pageYOffset > 100) {
        this.showScrollBtn = true;
      } else {
        this.showScrollBtn = false;
      }
    },
  },
  mounted() {
    // Add an event listener to detect when the user scrolls the page
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>
<style scoped>
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: -5px;
  z-index: 999;
  /* position: relative; */
}
</style>
