<template>
        <div class="container d-flex justify-content-end align-items-center mb-4 p-0 mt-0">
            <div class=" text-start fw-bold  w-50 px-3">
                <a href="https://drive.google.com/file/d/1G5oRChg82HCVgb7kjmT7Oeltw6qhDhQR/view" target="_blank" class="case-study-pdf">{{ projectPdf }}</a>
              </div>
        </div>
</template>
<script>
export default{
    props:{
     projectPdf:String
    }
}
</script>

<style scoped>
.case-study-pdf {
    font-size: 21.504px;
    line-height: 33.3312px;
    color: #000000;
    text-underline-offset: 5px;
    border-bottom: 2px solid #000000;
    text-decoration: none;
    padding-bottom: 3px;
  }
  
  .case-study-pdf:hover {
    /* background-color: #54b3d6;
        color: red !important; */
    border-bottom: 0px solid green !important;
  }

  @media (max-width: 728px) {
    .text-start{
        width: 100% !important;
        display: flex;
        align-items: center;
        margin-left: 30px !important;
    }
  }
  
</style>