<template>
    <section class="container mt-5 mb-5">

        <div class="px-4">
          <div class="row row-cols-1 row-cols-sm-3 row-cols-md-3" data-masonry='{"percentPosition": true }'>
            <div class="col  py-1 mb-5">
              <img src="@/assets/images/moontime-mobile1.png" alt="" class="img-fluid">
               <!-- <p class="text-start mt-2 mb-5" style="font-size: 12.0528px; color: #000000;">Searchable Routes List</p> -->
            </div>
            <div class="col  py-1 mb-5">
              <img src="@/assets/images/moontime-ui2.png" alt="" class="img-fluid">
              <!-- <p class="text-start mt-2 mb-5" style="font-size: 12.0528px; color: #000000;">Housecat brand guidelines.</p> -->
            </div>
            <div class="col  py-1 mb-5">
              <img src="@/assets/images/moontime-ui3.png" alt="" class="img-fluid">
              <!-- <p class="text-start mt-2 mb-5" style="font-size: 12.0528px; color: #000000;">Housecat brand guidelines.</p> -->
            </div>
          </div>
        </div>
       </section>
</template>