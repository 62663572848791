<template>
  <section class="container project-two-container mb-5">
    <div class="">
      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-2 px-4 align-tems-center"
      >
        <div class="col">
          <div class="text-start d-flex">
            <a
              href="#imageID"
              class="scroll-down me-3 d-flex flex-row align-items-center mb-4"
            >
              <span class="fw-bold">Scroll Down</span>
              <lord-icon
                class="mt-0"
                src="https://cdn.lordicon.com/rxufjlal.json"
                trigger="hover"
                style="width: 30px; height: 30px;"
              >
              </lord-icon>
            </a>
          </div>
        </div>
        <div class="col mb-5">
          <div class="project-details">
            <h2 class="company-name">Client</h2>
            <h4 class="company-name">{{ client }}</h4>
          </div>
          <hr class="mt-3" />
          <div class="project-details">
            <h2 class="company-name">Type</h2>
            <h4 class="company-name">{{ type }}</h4>
          </div>
          <hr class="mt-3 mb-5" />
          <div class="project-details">
            <h2 class="company-name">Year</h2>
            <h4 class="company-name">{{ year }}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    client: String,
    type: String,
    year: String
  }
}
</script>
<style scoped>
.project-two-container {
  margin-top: 10px !important;
  padding-top: 70px;
  font-size: 20.75px;
  font-weight: 500;
}
.scroll-down {
  text-decoration: none;
}
.scroll-down span {
  font-size: 21.504px;
  line-height: 33.3312px;
  color: #000000;
  text-underline-offset: 5px;
  text-decoration: underline;
  padding-bottom: 3px;
  margin-right: 20px;
}

.scroll-down span:hover {
  /* background-color: #54b3d6;
      color: red !important; */
  text-decoration: none;
}

.project-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 728px) {
  .project-two-heading {
    font-size: 3rem !important;
    margin: 0px !important;
  }
  .project-two-container {
    margin-top: 0px !important;
    padding-top: 50px !important;
    padding-left: 20px !important;
    padding-right: 10px !important;
  }
  .project-sm-p {
    font-size: 1.2rem !important;
    line-height: 1.4em !important;
  }
  .project-details {
    display: block;
  }
  .scroll-down{
    display: none !important;
  }
}
</style>
