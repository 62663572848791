<template>
    <section class="container px-5">
       <div class="centered-content">
          <h1 class="mt-5 text-start">Moontime TV Series, Offering <br> A Seamless Nightime Experience</h1>
          <p class="text-start mt-5">Designed with the ultimate goal of providing TV series enthusiasts with access to the mobile app and seamless browsing experience.As the visual designer, I played a key role in creating a modern and captivating nigh-time themed mood, using my experience in aesthetics and rsponsive design to ensure optimal performance accross all devices. The stunning design of the website serves as a landing site for Moontime app</p>
          <img src="@/assets/images/moontime-no-back.png" class="img-fluid" alt="">
       </div>
    </section>
</template>

<style scoped>
.centered-content{
    background-color:#F7F7FF;
    padding: 40px 80px !important;

    
}
h1{
    font-weight: 600;
    color: black;
}
p{
    font-size:21.25px;
    line-height:26.95px;
vertical-align:baseline;
letter-spacing:-0.35px;
word-spacing:0px;
font-weight:500;
}

@media (max-width: 728px) {
    .container{
        padding: 0px !important;
    }
   .centered-content{
    padding: 50px !important;
   }
}

</style>