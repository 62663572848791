<template>
  <button
    @click="scrollToTop"
    v-show="showScrollBtn"
    type="button"
    class="btn btn-floating btn-lg"
    id="btn-back-to-top"
    ref="btn-back-to-top"
  >
    <lord-icon
      src="https://cdn.lordicon.com/xsdtfyne.json"
      trigger="hover"
      style="width: 30px; height: 30px"
    >
    </lord-icon>
  </button>
  <project-hero
    projectTitle="Tulpen"
    projectDescription="Tulpen Restaurant app offers a convenient way for customers to order and discover delightful meals delivered timely"
    projectOs="iOS . Android Apps"
  />
  <tulpen-case-study-download projectPdf="View Tulpen Case Study PDF" />

  <project-details
    client="Younma, Netherlands"
    type="UI/UX Design"
    year="2021"
  />
  <project-image imageUrl="tulpen-hero.jpg" />
  <project-info
    projectHeader="Tulpen Restaurant App"
    projectInfo="As the UX designer, I was task by Youma to collaborate with the project
    manager and developers to develop a restaurant app called Tulpen. I tackled
    the initial research phase to empathise with the restaurant’s audience to
    understand their problem and turn gathered insights from the research into 
    an app designed with users and clients goals in mind. I tested and iterated 
    on the design to ensure users and the clients needs are met."
  />
  <contained-image imageUrl="tulpen-app.png" />
  <project-challenges
    projectBackgroundTitle="Challenges"
    projectBackgroundDescription="In the early stages of the Tulpen project, I found that traditional pictureless
    food menus and limited options resulted in long wait times, leading to poor
    customer experience and high abandon rates. User research and journey
    mapping backed this challenge, showing that customers struggled to    
    visualize meals and easily find their desired menu options. The findings
    emphasized the need for visual aids, such as photos, to help customers    
    make informed decisions. I implemented photo-based visualized    
    menus to improve customer engagement and satisfaction."
  />
  <project-role
    projectRoleTitle="My Role"
    projectRoleDescription="As the UX designer and researcher, I conducted research to empathise with
    users and turned gathered insights from research into an app design with   
    users front and center. I tested and iterated on the design to ensure users
    needs were met."
    projectRoleOne="Developing research plans, conduction usability study"
    projectRoleTwo="Analysing and synthesising research findings to inform design decisions"
    projectRoleThree="Collaborating with stakeholders as I create wireframes, prototypes, and high-fidelity mockups"
    projectRoleFour="Testing and evaluating the app usability"
    projectRoleFive="Ensuring that the app is accessible and inclusive for all users"
  />
  <contained-image imageUrl="tulpen-app-two.png" />
  <contained-image imageUrl="tulpen-app-three.png" />
  <contained-image imageUrl="tulpen-app-four.png" />
  <project-image imageUrl="tulpen-grid.jpg" />
  <project-info
    projectHeader="Visualised photo-based meal menu for delightful app experience"
    projectInfo="The Tulpen Restaurant app aimed to solve the common issues of traditional
    picture-less food menus and limited menu options, which were revealed
    through user research and journey mapping. To tackle these challenges, I made
    the strategic decision to incorporate photo-based visualised menus in the app
    interface. This design solution greatly improved the customer experience by
    allowing them to visually see the appearance of their meal choices, resulting in
    a more delightful and satisfying ordering process"
  />
  <contained-image imageUrl="tulpen-banner.jpg" />
  <project-info
    projectHeader="Outcome"
    projectInfo="As the Senior UX Designer on the Tulpen Restaurant App project, I am proud to report that the project was a huge Success. The implementation of photo-based visualised menu led to a 35% increase in completed orders and a 15% decrease in abandon rate. The visualized menus made it easier for customers to choose their desired meal, resulting in a more delightful and efficient ordering experience."
    projectSubInfo="The project also saw a 50% increase in average time spent on the app and a 30% decrease in bounce rate. The Tulpen App project demonstrates the impact of thoughtful and research-based UX design on customer experience and business outcomes."
  />
  <project-hr />
  <next-project
    nextProjectTitle="Pridelands"
    nextProjectPar="Pridelands is the narrative sharing and marketplace for  people and professional to share ideas &#38; projects, buy and sell and make impact"
    nextProjectParOne=""
    nextProjectParTwo=""
    nextProjectText="Next Project"
    nextProjectImage="pridelands-next.png"
  />
  <project-hr />
  <project-footer />
</template>

<script>
import ProjectHero from '@/components/ProjectHero.vue'
import ProjectImage from '@/components/ProjectImage.vue'
import ProjectDetails from '@/components/ProjectDetails.vue'
import ProjectInfo from '@/components/ProjectInfo.vue'
import ContainedImage from '@/components/ContainedImage.vue'
import ProjectChallenges from '@/components/ProjectChallenges.vue'
import ProjectRole from '@/components/PojectRole.vue'
import ProjectHr from '@/components/ProjectHr.vue'
import NextProject from '@/components/NextProject.vue'
import ProjectFooter from '@/components/ProjectFooter.vue'
import TulpenCaseStudyDownload from '@/components/TulpenCaseStudyDownload.vue'

export default {
  name: 'TulpenView',
  components: {
    ProjectHero,
    ProjectImage,
    ProjectDetails,
    ProjectInfo,
    ContainedImage,
    ProjectChallenges,
    ProjectRole,
    ProjectHr,
    NextProject,
    ProjectFooter,
    TulpenCaseStudyDownload
  },
  data() {
    return {
      showScrollBtn: false
    }
  },
  methods: {
    // This method scrolls the page to the top when the button is clicked
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    // This method is called when the user scrolls the page
    handleScroll() {
      // If the user has scrolled down more than 100 pixels, show the button
      if (window.pageYOffset > 100) {
        this.showScrollBtn = true
      } else {
        this.showScrollBtn = false
      }
    }
  },
  mounted() {
    // Add an event listener to detect when the user scrolls the page
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style scoped>
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: -5px;
  z-index: 999;
  /* position: relative; */
}
</style>
