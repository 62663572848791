<template>
  <section class="container mb-5">
    <div class="mt-5 px-4">
      <h1 class="text-start text-dark w-75">{{ projectHeader }}</h1>
      <p class="w-75 text-start project-info mt-3 mb-4">{{ projectInfo }}</p>
      <p class="w-75 text-start project-info  mb-5">{{ projectSubInfo }}</p>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    projectHeader: String,
    projectInfo: String,
    projectSubInfo: String
  }
}
</script>
<style scoped>
.project-info {
  font-size: 20.25px;
  line-height: 26.95px;
  font-weight: 500;
  vertical-align: baseline;
  letter-spacing: -0.35px;
  word-spacing: 0px;
  margin: 11.55px 0px;
  padding: 0px;
}
.text-dark{
    font-weight: 600;
}

@media (max-width: 728px) {
  .w-75 {
    width: 100% !important;
  }
}
</style>
