<template>
    <section class="container next-project-container">
        <div class="mb-3">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 px-4 align-tems-center">
            <div class="col">
              <h1 class="next-project text-start fw-bolder">{{nextProjectTitle}}</h1>
          <p class="mt-3 mb-3 text-start" style="font-size:21.25px;line-height:26.95px;margin:9.625px 0px 0px;padding: 0px 56.6562px 0px 0px;font-weight:500;color: #000000;">Mobile App and Website</p>
            </div>
            <div class="col">
              <p class="project-sm-p text-start">
                {{ nextProjectPar }}
              </p>
              <p class=" text-start px-3 first-par text-start">
                {{ nextProjectParOne }}
              </p>
              <p class="project-sm-p text-start px-4 second-par text-start">
                {{ nextProjectParTwo }}
              </p>
            </div>
          </div>
        </div>
        <router-link to="/pridelands">
          <div class="px-4 next-project-pic">
            <img :src="require('@/assets/images/' +  nextProjectImage + '')"  class="img-fluid" alt="">
            <div class="centered text-white">{{ nextProjectText }} <img src="@/assets/images/arrow-right-svgrepo-com.svg" alt="" style="height:15px"> </div>
          </div>
        </router-link>
      </section>
</template>

<script>
export default {
  props:{
    nextProjectTitle: String,
    nextProjectPar: String,
    nextProjectParOne:String,
    nextProjectParTwo:String,
    nextProjectText: String,
    nextProjectImage:String,
  } 
}
</script>
<style scoped>
.next-project{
    font-size: 50px;
    color: black;

}
.project-sm-p{
    font-size:21.35px;
    line-height:31.85px;
    vertical-align:baseline;
    letter-spacing:-0.35px;
    word-spacing:0px;
    margin:0px;
    padding:0px; 
    color: #000000;
}
.first-par{
  font-size:21.35px;
  line-height:31.85px;
  vertical-align:baseline;
  letter-spacing:-0.35px;
  word-spacing:0px;
  margin:0px;
  padding:0px; 
  color: #000000
}
.next-project-pic{
  position: relative;
  text-align: center;
}

.centered{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
}

@media (max-width: 728px) {
  .centered{
    font-size: 16px !important;
  }
  

}
</style>