<template>
    <section class="container mb-md-5">
      <div class="container">
        <img class="img-fluid mb-md-5" :src="require('@/assets/images/' +  imageUrl + '')" alt="">
      </div>
    </section>
  </template>
  <script>
  export default{
      props: {
      imageUrl: String,
      }
  }
  </script>
  
  <style scoped>
  @media (max-width: 728px) {
   img{
    margin-bottom: 3rem !important;;
   }
  }
  </style>