import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import Lottie from 'lottie-web'
import { defineElement } from 'lord-icon-element'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

defineElement(Lottie.loadAnimation)
createApp(App).use(store).use(router).mount('#app')
