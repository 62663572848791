<template>
  <section class="container project-two-container mb-5">
    <div class="">
      <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-2 px-4 align-tems-center"
      >
        <div class="col">
          <div class="text-start d-flex">
            <div
              data-iframe-width="150"
              data-iframe-height="270"
              data-share-badge-id="02eafb3a-5343-4acc-8deb-75cecfbb2cf7"
              data-share-badge-host="https://www.credly.com"
              src="//cdn.credly.com/assets/utilities/embed.js"
            ></div>
          </div>
        </div>
        <div class="col mb-5">
          <div class="project-details">
            <h2 class="company-name">Contact</h2>
            <h4 class="company-name"></h4>
          </div>
          <hr class="mt-3" />
          <div class="project-details">
            <h2 class="company-name">Email</h2>
            <h4 class="company-name">{{ email }}</h4>
          </div>
          <hr class="mt-3 mb-5" />
          <div class="project-details">
            <h2 class="company-name">Phone Number</h2>
            <h4 class="company-name">{{ phoneNumber }}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    email: String,
    phoneNumber: String
  }
}
</script>
<style scoped>
.project-two-container {
  margin-top: 10px !important;
  padding-top: 70px;
  font-size: 20.75px;
  font-weight: 500;
}
.scroll-down {
  text-decoration: none;
}
.scroll-down span {
  font-size: 21.504px;
  line-height: 33.3312px;
  color: #000000;
  text-underline-offset: 5px;
  text-decoration: underline;
  padding-bottom: 3px;
  margin-right: 20px;
}

.scroll-down span:hover {
  /* background-color: #54b3d6;
        color: red !important; */
  text-decoration: none;
}

.project-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 728px) {
  .project-two-heading {
    font-size: 3rem !important;
    margin: 0px !important;
  }
  .project-two-container {
    margin-top: 0px !important;
    padding-top: 50px !important;
    padding-left: 20px !important;
    padding-right: 10px !important;
  }
  .project-sm-p {
    font-size: 1.2rem !important;
    line-height: 1.4em !important;
  }
  .project-details {
    display: block;
  }
  .scroll-down {
    display: none !important;
  }
}
</style>
