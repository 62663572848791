<template>
    <button
     @click="scrollToTop"
     v-show="showScrollBtn"
      type="button"
      class="btn btn-floating btn-lg"
      id="btn-back-to-top"
      ref="btn-back-to-top"
    >
      <lord-icon
        src="https://cdn.lordicon.com/xsdtfyne.json"
        trigger="hover"
        style="width: 30px; height: 30px"
      >
      </lord-icon>
    </button>
    <project-hero
      projectTitle="Enoch Y.Offei"
      projectDescription="I'm a senior product designer that cares about creating delightful & meaningful designs that empower lives and shape the future"
      projectOs="Cv + Cover Letter"
    />
    <cv-document projectPdf="View CV "/>
    <cover-letter-download projectPdf="View Cover Letter"/>

   <contact-info email="hi@enochpro.com" phoneNumber="+233 557 582 670"/>
    <project-image imageUrl="Enoch-Pro-Portfolio-Banner.png" />
    <app-experience/>
    <project-footer/>
  
  </template>
  
  <script>
  import ProjectHero from '@/components/ProjectHero.vue'
  import ProjectImage from '@/components/ProjectImage.vue'
  import ProjectInfo from '@/components/ProjectInfo.vue'
  import ContainedImage from '@/components/ContainedImage.vue'
  import ProjectChallenges from '@/components/ProjectChallenges.vue'
  import ProjectRole from '@/components/PojectRole.vue'
  import ProjectHr from '@/components/ProjectHr.vue'
  import NextProject from '@/components/NextProject.vue'
  import ProjectFooter from  '@/components/ProjectFooter.vue'
  import PridelandsCaseStudyDownload from '@/components/PridelandsCaseStudyDownload.vue'
  import PridelandsWebLink from '@/components/PridelandsWebLink.vue'
  import CoverLetterDownload from '@/components/CoverLetterDownload.vue'
  import CvDocument from '@/components/CvDocument.vue'
  import ContactInfo from '@/components/ContactInfo.vue'
  import AppExperience from '@/components/AppExperience.vue'
  export default {
    name: 'CvLetterView',
    components: {
      ProjectHero,
      ProjectImage,
      ProjectInfo,
      ContainedImage,
      ProjectChallenges,
      ProjectRole,
      ProjectHr,
      NextProject,
      ProjectFooter,
      PridelandsCaseStudyDownload,
      PridelandsWebLink,
      CoverLetterDownload,
      CvDocument,
      ContactInfo,
      AppExperience
    },
    data() {
    return {
      showScrollBtn: false,
    };
  },
  methods: {
    // This method scrolls the page to the top when the button is clicked
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // This method is called when the user scrolls the page
    handleScroll() {
      // If the user has scrolled down more than 100 pixels, show the button
      if (window.pageYOffset > 100) {
        this.showScrollBtn = true;
      } else {
        this.showScrollBtn = false;
      }
    },
  },
  mounted() {
    // Add an event listener to detect when the user scrolls the page
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener("scroll", this.handleScroll);
  },
  }
  </script>
  <style scoped>
  #btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: -5px;
    z-index: 999;
    /* position: relative; */
  }
  </style>
  