<template>
  <button
    @click="scrollToTop"
    v-show="showScrollBtn"
    type="button"
    class="btn btn-floating btn-lg"
    id="btn-back-to-top"
    ref="btn-back-to-top"
  >
    <lord-icon
      src="https://cdn.lordicon.com/xsdtfyne.json"
      trigger="hover"
      style="width: 30px; height: 30px"
    >
    </lord-icon>
  </button>
  <project-hero
    projectTitle="Moontime"
    projectDescription="Moontime: Your go-to TV series app. Explore a vast library of shows, track your all time favorites, and immerse yourself in the world of TV series like never before."
    projectOs="Redesign . Responsive Website"
  />
  <moontime-case-study projectPdf="View Moontime Digital Wireframe PDF" />
  <project-details
    client="Jobsity, New York"
    type="Visual Design"
    year="2023"
  />
  <project-image imageUrl="moontime-hero.jpg" />
  <project-info
    projectHeader="Mootime Tv Series App"
    projectInfo="As a designer on Mootime, I was thrilled to create a captivating night-time themed platform for TV series enthusiasts. I leveraged my expertise in user-centered and responsive design to create a seamless experience for users, resulting in a highly functional and visually appearing website"
  />
  <contained-image imageUrl="moontime1.jpg" />
  <project-challenges
    projectBackgroundTitle="Design Process"
    projectBackgroundDescription="As a Visual designer on the Moontime project, my process involved understanding the needs and preferences of TV series enthusiasts. The client provided insights about their target audience. Based on th insights gathered, I created wireframes and prototypes to iteratively test and refine user experience. I also closely collaborated with the the developer on the project to ensure that the design was technically feasible and aligned with the project constraints. The final product was a modern and captivating night-time themed platform that effectively combined a vast database of TV series with a sense of cinematic feel and connection among fans"
  />
  <centered-header centeredText="Wireframe Preview" />
  <contained-image imageUrl="wireframe1.png" />
  <contained-image imageUrl="wireframe2.png" />
  <centered-header centeredText="Mockup Preview" />
  <contained-image imageUrl="moontime.png" />
  <contained-image imageUrl="moontime-ui.png" />
  <mockup-preview />
  <contained-project-info />
  <project-hr />
  <tulpen-project-next
    nextProjectTitle="Tulpen"
    nextProjectPar="Tulpen Restaurant app offers a convenient  way for customers to order and discover delightful meals delivered timely"
    nextProjectParOne=""
    nextProjectParTwo=""
    nextProjectText="Next Project"
    nextProjectImage="tulpen-next.png"
  />
  <project-hr />
  <project-footer />
</template>

<script>
import ProjectHero from '@/components/ProjectHero.vue'
import ProjectImage from '@/components/ProjectImage.vue'
import ProjectDetails from '@/components/ProjectDetails.vue'
import ProjectInfo from '@/components/ProjectInfo.vue'
import ContainedImage from '@/components/ContainedImage.vue'
import ProjectChallenges from '@/components/ProjectChallenges.vue'
import ProjectRole from '@/components/PojectRole.vue'
import ProjectHr from '@/components/ProjectHr.vue'
import TulpenProjectNext from '@/components/TulpenProjectNext.vue'
import ProjectFooter from '@/components/ProjectFooter.vue'
import CenteredHeader from '@/components/CenteredHeader.vue'
import MockupPreview from '@/components/MockupPreview.vue'
import ContainedProjectInfo from '@/components/ContainedProjectInfo.vue'
import MoontimeCaseStudy from '@/components/MoontimeCaseStudy.vue'
export default {
  name: 'MoonTime',
  components: {
    ProjectHero,
    ProjectImage,
    ProjectDetails,
    ProjectInfo,
    ContainedImage,
    ProjectChallenges,
    ProjectRole,
    ProjectHr,
    TulpenProjectNext,
    ProjectFooter,
    CenteredHeader,
    MockupPreview,
    ContainedProjectInfo,
    MoontimeCaseStudy
  },
  data() {
    return {
      showScrollBtn: false
    }
  },
  methods: {
    // This method scrolls the page to the top when the button is clicked
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    // This method is called when the user scrolls the page
    handleScroll() {
      // If the user has scrolled down more than 100 pixels, show the button
      if (window.pageYOffset > 100) {
        this.showScrollBtn = true
      } else {
        this.showScrollBtn = false
      }
    }
  },
  mounted() {
    // Add an event listener to detect when the user scrolls the page
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style scoped>
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: -5px;
  z-index: 999;
  /* position: relative; */
}
</style>
